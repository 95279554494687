<template>
  <div ref="body">
    <template v-if="!isApplet">
      <custom-header active="design" :subActive="current"></custom-header>
    </template>

    <div class="content-container ">
      <div v-if="!isApplet" class="left-bar ">
        <div class="title text-bold">展会特色板块</div>
        <div class="main-menu" id="main-menu">
          <div class="row">
            <template v-for="(item, idx) in themes">
              <div :key="item.partsId" :class="['theme', themeObj.partsId == item.partsId ? 'active' : '']" @click="switchTheme(item.partsId, idx)"><h1>{{item.partsName}}</h1></div>
            </template>
          </div>
        </div>
      </div>
      <div>
        <h1 class="theme-title">{{themeObj.partsName}}</h1>
        <div class="theme-content">
          <div v-if="themeObj.type == 1" v-html="themeObj.content"></div>
          <div v-if="themeObj.type == 2">
            <template v-for="(img, idx) in themeObj.imgList">
              <img :src="img" :key="idx">
            </template>
          </div>
          <div v-if="themeObj.type == 3">
            <img :src="themeObj.networkImage" :alt="themeObj.partsName">
          </div>
        </div>
      </div>
    </div>
    
    <template v-if="!isApplet">
      <custom-footer :friendLink="friendLink"></custom-footer>
    </template>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getThemeList } from '@/api/index.js';

export default {
  // name: '深圳国际家具展',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'theme',
      partsId: '',
      themes: [],
      themeObj: {},
      isApplet: false, // 是否在小程序内显示
    }
  },
  created() {
    this.isApplet = this.$route.query.isApplet == 1 || false;
    this.partsId = this.$route.query.id || '';
    this.getThemes();
  },
  mounted() {
    
  },
  methods: {
    switchTheme(partsId, idx) {
      this.partsId = partsId;
      this.themeObj = this.themes[idx];
      console.log(this.themeObj);
    },
    getThemes() {
      getThemeList().then(res => {
        this.themes = (res.resultData || []).map(e => {
          if (e.type == 2) {
            e.imgList = e.batchImage.split(',,,');
          }
          return e;
        });
        if (this.themes.length > 0) {
          if (this.partsId) {
            this.themeObj = this.themes.find(e => e.partsId == this.partsId)
          } else{
            this.themeObj = this.themes[0]
          }
        }

      })
    },
  },
}
</script>

<style lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .theme-content {
    img {
      display: block;
      width: 100%;
      margin: 5px auto;
      height: auto;
      border-radius: 4px;
    }
  }
}
// PC端
@media screen and (min-width: 1024px) {
  .theme-content {
    img {
      display: block;
      width: 90%;
      margin: 5px auto;
      height: auto;
      border-radius: 1px;
    }
  }
}
</style>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    .title {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }
    
    .main-menu {
      // width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0;
      }
      
      .row {
        width: auto;
        white-space: nowrap; 
        
        .theme {
          display: inline-block;
          width: 160px;
          cursor: pointer;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #fff;
          line-height: 30px;
          
          &.active {
            border-color: #060211;
          }
          h1 {
            font-weight: normal;
            font-size: 13px;
            font-family: 'SourceHanSansCN-Medium';
          }
          
        }
        
      }
    }
    
    .theme-title {
      font-size: 16px;
      text-align: center;
      margin: 20px auto 10px;
    }
    .theme-content {
      width: 375px;
      padding: 10px 17px;
      font-size: 14px;
      
      img {
        display: block;
        width: 100%;
        margin: 5px auto;
        height: auto;
      }
    }
  }
  
}

// PC
@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    display: flex;
    width: 280px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 80px;
      padding: 10px;
      box-sizing: border-box;
    }
    .title {
      font-size: 6.5px;
      margin-bottom: 7px;
      text-align: center;
      
    }
    .theme {
      width: 60px;
      margin: 2px 0;
      cursor: pointer;
      text-align: center;
      border-radius: 1px;
      border: 0.5px solid #fff;
      line-height: 12px;
      
      &.active,
      &:hover {
        border-color: #060211;
      }
      h1 {
        font-weight: normal;
        font-size: 3.6px;
        font-family: 'SourceHanSansCN-Medium';
      }
      
    }
    
    .theme-title {
      font-size: 6px;
      text-align: center;
      margin-top: 10px;
    }
    .theme-content {
      width: 180px;
      margin-left: 10px;
      padding: 10px 0;
      font-size: 4.5px;
    }
  }
  
}

</style>
